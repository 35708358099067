<template>
	<v-container class="fill-height" fluid>
		<v-row align="center" justify="center" class="ma-0">
			<v-col class="pa-0 px-3" align="center">
				<v-icon :size="affichagePortable ? 60 : 100">mdi-traffic-cone</v-icon>
				<div class="mt-6"><span class="font-weight-bold">SP Planning</span> est actuellement en maintenance. Merci de revenir un peu plus tard...</div>
				<v-btn outlined @click="onClickHome" color="primary" class="mt-8">Retour au site</v-btn>
			</v-col>
		</v-row>
    </v-container>
  
</template>

<script>
	export default {
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
		},
		methods: {
			onClickHome() {
				this.$router.push('/')
			}
		},
	}
</script>